html {
    background-color: white;
    font-family: 'Varta';
}

body {
    margin: 0;
    min-height: 100vh;
}

/* bootstrap navbar */

/* remove default bright outline on hamburger */
button:focus {
    outline:none !important;
}

/* vertically center portal logo */
.navbar-brand > #logo {
    padding-top: 20px;
}

/* enable dropdown submenu on hover */
.navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}

/* this is probably the one to mess with if menus need to be dropright or left in future? */
.dropdown-submenu>.dropdown-menu {
    top:150%;
}

/* enable gold hover color on links */
.nav-link:hover {
    color: gold !important;
}

.nav-link:hover:after {
    color: gold !important;
}

/* enable hover */
.dropdown-item:hover {
    background-color: black !important;
    color: gold !important;
}

/* dropdown positioning */
#navbarDropdownMenuLink > .dropdown-item > .dropdown-menu {
    padding-top: -100px !important;
}

/* pretty sure this doesn't do anything anymore */
/*.dropdown-menu >li > a:hover:after {
    text-decoration: underline;
} */

/* changes to bootstrap default dark theme aesthetics */
.nav-link {
    color: white !important;
    padding: 20px !important;
}

.dropdown-menu {
    background-color: black !important;
    margin-top: -20px !important;
}

.dropdown-item {
    color: white !important;
    background-color: black !important;
}

a.dropdown-item:hover {
    background: black linear-gradient(180deg, black, black) repeat-x !important;
    background-color: black !important;
}

/* center dropdown menus in responsive mode */
@media screen and (max-width: 900px) {
    .dropdown-item {
        text-align: center !important;
    }
  }

/* portal and updates styling */

.grid-container#first {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 700px);
    z-index:1;
    width: 100%;
    margin-top: 0px;
    margin-bottom:-10rem;
    padding: 0;
}

img#background {
    width: 100%;
    height: auto;
    max-height: 550px;
    min-height: 550px;
    object-fit: cover;
}

#text-position {
    position: absolute;
    width: 50%;
    top: 0px;
    left: 0px;
    text-align: center;
    margin-top: 5.67rem;
}

#portal h1 {
    color:darkgreen;
    position: relative;
    /* font-weight:bold; */
    font-size: 4.5rem;
    margin-top: 2.5rem;
}

#portal p {
    color:#052419;
    position: relative;
    font-weight: 500;
    font-size: 1.8rem;
    margin: 0px 50px 50px 50px;
}

* {box-sizing:border-box}


/* Slideshow container */

.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }

  /* Hide the images by default */

  .mySlides {
    display: none;
  }

  /* Next & previous buttons */

  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white !important;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  /* Position the "next button" to the right */

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  .prev {
    left: 0;
  }

  /* On hover, add a black background color with a little bit see-through */

  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
    text-decoration: none;
  }

  /* Caption text */

  .text {
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
    color: black;
    font-size: 12px;
    padding: 8px 12px 0px;
    position: absolute;
    width: 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
  }

  /* Number text (1/3 etc) */

  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    font-weight: bold;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  #title_url a {
    color: black;
    font-size: 15px;
    padding: 0px;
    text-decoration:underline;
  }

  /* The dots/bullets/indicators */

  .active {
    background-color: #717171;
  }

  /* end of W3Schools code */

#updates {
    background-color: white;
    text-align: center;
    min-width: 0;
    min-height: 0;
    padding-top: 200px;
    margin-bottom: 150px;
    margin-top: -150px;
}

h2#h_updates {
    color: #000000;
    text-align: center;
    /* font-weight: bold; */
    font-size: 3rem;
    /*margin-bottom: 1.5rem;*/
    /*margin-bottom:-10rem;*/
    text-decoration: none;
}

.post_text > h3 {
    color: black;
    font-size: 1.5rem;
}

.flex-container#latest {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
}

@media screen and (max-width: 900px) {
    .grid-container#first {
        display: grid;
        grid-template-columns: 1fr;
    }

    #portal p {
        color:#052419;
        /* background-color: white;
        opacity: 50%;*/
        position: relative;
        font-weight: 500;
        font-size: 1.4rem;
        margin: 0px 50px 50px 50px;
    }

    #text-position {
        width: 100%;
    }

    #updates {
        min-width: 0;
        min-height: 0;
        padding-top: 0px;
        margin-top: -200px;
    }

    #latest {
        min-width: 0;
        min-height: 0;
        padding-bottom: 75px;
    }
}

/* data section */

h2 {
    margin-top: 3rem;
    text-align: center;
    font-size: 70px;
    text-decoration: underline gold 15%; /* shorthand for text-decoration-line, text-decoration-color, and text-decoration-thickness */
    text-underline-offset: 30%;
    color: #000000;
    margin-bottom: 70px; /* adjustment for bootstrap side effects */
}

.container#data {
    display: grid;
    justify-content: center;
    row-gap: 50px;
    column-gap: 50px;
    grid-template-columns: 300px 300px 300px;
    margin-bottom: 100px;
    margin-top: 0px;
}

.item h3 {
    margin-left: 30px;
}

#data p {
    font-weight: lighter;
}

.item#data {
    background-color: aliceblue;
}

.item#data img:hover {
    filter: grayscale(100%);
    opacity: 50%;
}

.item a {
    text-decoration: none;
    /* margin: -50px 15px; */
    color: #000000;
    text-align: left;
    font-size: 20px;
}

.item a:hover {
    color: gold;
}

#data p {
    margin: 0px 30px 30px;
}

.item-1#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-2#data {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-3#data {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-4#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-5#data {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-6#data {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-7#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
}

.item-8#data {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
}

.item-9#data {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 3;
}

@media screen and (max-width: 900px) {
  .container#data {
    display: grid;
    justify-content: center;
    row-gap: 50px;
    column-gap: 50px;
    grid-template-columns: 300px;
    margin-bottom: 50px;
}
  .container#bottom {
    display: grid;
    justify-content: center;
    row-gap: 50px;
    column-gap: 50px;
    grid-template-columns: 300px;
    margin-bottom: 100px;
}
  .item-1#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

  .item-2#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
}

  .item-3#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
}
  .item-4#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;
}

  .item-5#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 5;
    grid-row-end: 5;
}
  .item-6#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 6;
    grid-row-end: 6;
}
.item-7#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 7;
    grid-row-end: 7;
}
.item-8#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 8;
    grid-row-end: 8;
}
.item-9#data {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 9;
    grid-row-end: 9;
}
}

/* here's the ABOUT section */

.grid-container#third {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 700px);
    /*background-position: right;
    background-image: url("pdx2.jpg");
    background-attachment: fixed;
    background-size: 100% 100%;
    background-repeat: no-repeat;*/
    border: 0;
}

#about-grid {
    background-color: whitesmoke;
}

.flex-container#about {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    align-items: center;
}

#about p {
    color: #000000;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.1rem;
    font-weight: lighter;
}

img#background2 {
    height:100%;
    width: cover;
    max-width: 100%;
    object-fit:cover;
}

@media screen and (max-width: 900px) {
    .grid-container#third {
        display: grid;
        grid-template-columns: 1fr;
        background-image: none;
    }
}

/* our partners section */


.container#partners {
    display: grid;
    justify-content: center;
    row-gap: 50px;
    column-gap: 50px;
    justify-items: center;
    margin-top: 75px;
    margin-bottom: 50px;
}

.item-1#partners {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-2#partners {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-3#partners {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-4#partners {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-5#partners {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-6#partners {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-7#partners {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-8#partners {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-9#partners {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-10#partners {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 2;
}

@media screen and (max-width: 900px) {
  .container#partners {
    display: grid;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: 200px 200px;
}

.item-1#partners {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-2#partners {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
}

.item-3#partners {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-4#partners {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
}

.item-5#partners {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
}

.item-6#partners {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
}

.item-7#partners {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;
}

.item-8#partners {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 4;
}

.item-9#partners {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 5;
    grid-row-end: 5;
}

.item-10#partners {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 5;
}
}

/* CSS for Contact section */
/* header has its own div */

#contact_header {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    text-align: center;
    align-items: center;
    border-top: 0;
    background: whitesmoke;
}

/* content is in a grid */

#contact-us {
    background-color: whitesmoke;
    display: grid;
    justify-content: center;
    row-gap: 50px;
    column-gap: 100px;
    grid-template-columns: auto auto;
    background: whitesmoke;
    padding-bottom: 75px;
}

#contact-us .grid-item {
    margin-top: 0px;
    padding-top: 0px;
}

/* left side of page */

.grid-item#text {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-top: 0px;
}

#contact-us p#inquiries {
    padding-bottom: 20px;
}

h4#inquiries {
    margin-top: 0px;
    padding-top: 0px;
}

#text h4 {
    color: black;
    /* font-weight: bold; */
    font-size: 1.5rem;
}

#text p {
    color: #000000;
    font-weight: lighter;
    font-size: 1.1rem;
}


/* right side of page / contact form */

.grid-item#form {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-top: -28px;
}

#form ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: whitesmoke;
    /*margin-top: -10%;*/

}

#form ul h4 {
    margin-right: 4rem;
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
}

label {
    color: #000000;
}

input {
    width: 450px;
    height: 25px;
    background-color: whitesmoke;
    border: 1px solid black;
    margin-bottom: 5px;
}

/*input:hover {
    background-color: lightgray;
}*/

/*#message {
    height: 100px;
}*/

.message > #id_message {
    text-align:left;
}

#contactform {
    width: 455px;
    height: 20px;
}

#submit {
    float: right;
    padding: 10px;
    margin-top: 5px;
    /*margin-bottom: 10px;*/
    width: 160px;
    height: 45px;
    background-color: black;
    color: gold;
    font-weight: bold;
    border: none;
    letter-spacing: 0.5px;
    transition-duration: 0.5s;
}

#submit:hover {
    background-color: gold;
    color: black;
    border: none;
}

form label, input {
    display: flex;
    flex-direction: column;
}

form {
    margin-top: 30px;
}

input:focus {
    outline:none !important;
}

@media screen and (max-width: 900px) {
    #contact-us {
        /* margin-bottom doesn't extend background past button */
        display: grid;
        justify-content: center;
        row-gap: 50px;
        grid-template-columns: auto;
        padding-bottom: 10%;
    }

    #text h4{
        text-justify: left;
        justify-items: center;
    }
    .grid-item#text {
       grid-column-start: 1;
       grid-column-end: 1;
       grid-row-start: 1;
       grid-row-end: 1;
       margin-left: 5%;
    }

    .grid-item#form {
       grid-column-start: 1;
       grid-column-end: 1;
       grid-row-start: 2;
       grid-row-end: 2;
    }

    #form ul {
        margin-top: -2%;
        margin-left: -4%;
    }

}


#latest_posts #text {
    text-align: center;
    background-color: whitesmoke;
}

.grid-container#email_success {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-top: 125px;
    background-position: right;
    background-image: url('/static/img/boat.jpg');
    background-attachment: fixed;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    border: 0;
}

#email_success > .grid-item {
    height: 535px;
}

#email_success #text {
    text-align: center;
    background-color: whitesmoke;
}

#email_success h2 {
    margin-top: 150px;
    font-size: 50px;
}

.grid-container#email_error {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 535px;
    margin-top: 125px;
    background-position: right;
    background-image: url('/static/img/something_went_wrong.jpg');
    background-attachment: fixed;
    background-size: 75% 100%;
    background-repeat: no-repeat;
    border: 0;
}

#email_error #text {
    text-align: center;
    background-color: whitesmoke;
}

#email_error h2 {
    margin-top: 150px;
    font-size: 50px;
}

/* transit thumbnail landing page styling */

.transit_links_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.trimet_link {
    width: 48%; /* Adjust the width as needed */
    background-color: #fff;
    padding: 80px;
    margin-bottom: 40px;
    margin-top: 100px;
    box-sizing: border-box;
}

.c-tran_link {
    width: 48%; /* Adjust the width as needed */
    background-color: #fff;
    padding: 80px;
    margin-bottom: 40px;
    margin-top: 100px;
    box-sizing: border-box;
}


.main-block-container {
    padding-bottom: 280px;
    width: 100%;
}

footer {
    font-size: 0.7rem;
    text-align: right;
    background: whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    border-top: solid 1px lightgrey;
    background-color: white;

    width: 100%;
    position: absolute;
    bottom: 0;
}

.container#f_partner {
    display: grid;
    justify-content: center;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(10, 1fr);
    justify-items: center;
}

.container#f_partner a {
    height: 10px;
}

.container#f_partner a, img {
    margin-bottom: 20px;
}

.logo {
    padding: 20px;
}

#metro {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

#odot {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
}

#rtc {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
}

#usdot {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
}

#psu {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
}

#pdx {
    grid-column-start: 6;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
}

#ctran {
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
}

#clark {
    grid-column-start: 8;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 1;
}

#trec {
    grid-column-start: 9;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 1;
}

#trimet {
    grid-column-start: 10;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 1;
}

@media screen and (max-width: 900px) {
    .container#f_partners {
        display: grid;
        justify-content: center;
        row-gap: 20px;
        column-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    #metro {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #odot {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #rtc {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #usdot {
        grid-column-start: 4;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #psu {
        grid-column-start: 5;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #pdx {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
    }

    #ctran {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
    }

    #clark {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
    }

    #trec {
        grid-column-start: 4;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
    }

    #trimet {
        grid-column-start: 5;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 2;
    }
}

/* fix the problem of anchor links jumping too low */
#about {
    scroll-margin-top: 100px;
}

#contact_header {
    scroll-margin-top: 100px;
}

.carousel-control-prev {
    background: none;
    border: none;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001));
}

.carousel-control-next {
    background: none;
    border: none;
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001));
}

/* fhwa css */

.grid-container#first_fhwa {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: repeat(1, 150px);
    grid-auto-columns: max-content;
    z-index: 1;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 50px;
    background-color: whitesmoke;
}

headline1 {
    margin-bottom: -100px;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

#headline2 {
    margin-bottom: -100px;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
}

#headline3 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
}

.container#headlines {
    display: grid;
    justify-content: center;
    justify-items: left;
    padding-left: 50px;
}

#fhwa_logo {
    padding-top: 15px;
}

/* row of logos */

.container#logos_fhwa {
    display: grid;
    justify-content: center;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    padding-top: 30px;
}

.logo {
    padding: 20px;
}

#metro_l {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

#odot_l {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
}

#portal_l {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
}

#psu_l {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
}

#trimet_l {
    padding-top: 30px;
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
}

#pdx_l {
    grid-column-start: 6;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
}

#text {
    padding-left: 170px;
    padding-right: 170px;
    padding-bottom: 50px;
}

#fwy_img {
    padding-left: 170px;
}

/* text */

#text p {
    font-weight: lighter;
}

/* main section */

#main {
    background-color: whitesmoke;
    display: grid;
    justify-content: center;
    row-gap: 50px;
    column-gap: 100px;
    grid-template-columns: auto auto;
    background: white;
    padding-bottom: 75px;
    padding-top: 100px;
}

#main .grid-item {
    margin-top: 0px;
    padding-top: 0px;
}

/* left side of page */

#fwy_img {
    margin-left: -125px;
    padding-right: 50px;
}

/* right side of page */

#art_h4 {
    border-bottom: 1px solid;
}

#fwy_h4 {
    border-bottom: 1px solid;
}

#transit_h4 {
    border-bottom: 1px solid;
}

/* responsive design for top headlines */

@media screen and (max-width: 1090px) {
    #headlines h3 {
        font-size: 20px;
    }
}

/* responsive design for logos */

@media screen and (max-width: 900px) {
    .container#logos {
        display: grid;
        justify-content: center;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        padding-top: 30px;
    }

    #metro_l {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    
    #odot_l {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    
    #portal_l {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    
    #psu_l {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
    }
    
    #trimet_l {
        padding-top: 30px;
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
    }
    
    #pdx_l {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
    }

    #fwy_img {
        padding-right: 10px;
    }
}